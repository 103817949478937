import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import FormHint from '../elements/FormHint'
import FormLabel from '../elements/FormLabel'
import Input from '../elements/Input'
import Button from '../elements/Button'
import Select from '../elements/Select'
import Slider from '../elements/Slider'
import Loader from "react-loader-spinner";
import _ from 'lodash'
import Alert from '../elements/Alert'
import InputFile from '../elements/InputFile'
import api from '../../services/api'

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

const Economy = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    pushLeft,
    invertColor,
    invertMobile,
    alignTop,
    invertDesktop,
    ...props
}) =>
{

    const [form, setForm] = useState({
        name: "",
        phone: "Não informado",
        email: "",
        consumption: 200,
        cnpj: "",
        enterprise: "",
        state: "",
        segment: "Não informado",
        description: "Não informado"
    })
    const [file, setFile] = useState([])
    const [done, setDone] = useState(false)
    const [sent, wasSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)
    const [err, setErr] = useState('')

    const states = [
        'Acre',
        'Alagoas',
        'Amapá',
        'Amazonas',
        'Bahia',
        'Ceará',
        'Distrito Federal',
        'Espírito Santo',
        'Goiás',
        'Maranhão',
        'Mato Grosso',
        'Mato Grosso do Sul',
        'Minas Gerais',
        'Pará',
        'Paraíba',
        'Paraná',
        'Pernambuco',
        'Piauí',
        'Rio de Janeiro',
        'Rio Grande do Norte',
        'Rio Grande do Sul',
        'Rondônia',
        'Roraima',
        'Santa Catarina',
        'São Paulo',
        'Sergipe',
        'Tocantins'
    ]

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap start-content',
        pushLeft && 'push-left'
    );

    const handleSubmit = () =>
    {
        setLoading(true)
        setTimeout(() =>
        {
            setLoading(false)
            setDone(true)
        }, 2000)
    }

    const handleSend = async () =>
    {
        if (_.isEmpty(form.name)
            || _.isEmpty(form.email)
            || _.isEmpty(form.cnpj)
            || _.isEmpty(form.enterprise)
            || _.isEmpty(form.state)
        ) {
            setErr('Campo obrigatório não preenchido!')
            return
        }
        setPageLoading(true)
        form.title = `Novo orçamento da Solareasy - ${form.name}`
        form.to = process.env.REACT_APP_EMAIL_SENDGRID_DESTINATION
        form.from = process.env.REACT_APP_EMAIL_SENDGRID_SHIPPING
        form.consumption = 'R$' + form.consumption
        form.templateId = process.env.REACT_APP_TEMPLATE
        if (!_.isEmpty(file)) form.file = file.file

        await api.sendEmail(form)
            .then(() =>
            {
                handleSendLead()
                wasSent(true)
            })
            .catch((err) => console.error(err))

        setTimeout(() =>
        {
            setPageLoading(false)
        }, 2000)

    }

    const handleSendLead = async () =>
    {
        return await api.sendNewLead({ email: form.email, itsAllowed: true, from: "solareasy" })
    }

    return (
        <section
            {...props}
            style={{ backgroundColor: 'white', }}
            className={outerClasses}
        >
            <div className="container reveal-from-bottom " data-reveal-delay="200" style={{ marginTop: '50px', minHeight: '670px', minWidth: '18em' }}>
                <div className={innerClasses} >
                    {!pageLoading ?
                        <div className="contact-content has-shadow" style={{ padding: '20px', borderRadius: '5px', height: 'auto' }}>
                            {sent ?
                                <div style={{ height: '18vh' }}>
                                    <h3 className="text-color-primary">Orçamento enviado</h3>
                                    <p>Em breve nosso time entrará em contato por telefone ou email!</p>
                                </div>
                                :
                                <div>
                                    {done ?
                                        <>
                                            <FormLabel>
                                                <h3 className="text-color-primary">Economia de aproximadamente&nbsp;
                                                    <span style={{ color: '#048585' }}>R${(12 * 0.15 * form.consumption).toFixed(2).replace('.', ',')}</span>&nbsp;anualmente.
                                                </h3>
                                                {err && <Alert text={err} type="error" style={{ display: 'flex', width: '100%', height: '50px' }} />}
                                            </FormLabel>
                                            <p>Preencha as seguintes informações para que possamos atender suas demandas de forma personalizada!</p>
                                        </>
                                        :
                                        <h3 className="mt-0 mb-0 text-color-primary" >
                                            Simulação de economia
                                        </h3>}
                                    <FormHint>
                                        <div style={{ marginTop: '10px' }}>

                                            <Input name="name" type="text" onChange={(e) => setForm({ ...form, name: e.target.value })} style={{ border: 'none', borderRadius: '5px' }} placeholder="Nome completo" />
                                            <Input name="email" type="email" onChange={(e) => setForm({ ...form, email: e.target.value })} style={{ border: 'none', borderRadius: '5px', marginTop: '10px' }} placeholder="Email para contato" />

                                            <div>
                                                {done ?
                                                    <div>
                                                        <Input
                                                            style={{ border: 'none', borderRadius: '5px', marginTop: '10px' }}
                                                            name="CNPJ"
                                                            onChange={(e) => setForm({ ...form, cnpj: e.target.value })}
                                                            type="number"
                                                            placeholder="CNPJ" />
                                                        <Input
                                                            style={{ border: 'none', borderRadius: '5px', marginTop: '10px' }}
                                                            name="enterprise name"
                                                            type="text"
                                                            onChange={(e) => setForm({ ...form, enterprise: e.target.value })}
                                                            placeholder="Nome da empresa" />

                                                        <Select
                                                            placeholder="Estado"
                                                            name="state"
                                                            onChange={(e) => setForm({ ...form, state: e.target.value })}
                                                            style={{ marginTop: '10px', border: 'none', borderRadius: '5px', color: "#9CA9B3" }}>
                                                            {_.map(states, state =>
                                                            {
                                                                return <option value={state}>{state}</option>
                                                            })}
                                                        </Select>

                                                        <Input
                                                            name="phone"
                                                            type="phone"
                                                            onChange={(e) => setForm({ ...form, phone: e.target.value })}
                                                            style={{ border: 'none', borderRadius: '5px', marginTop: '10px' }}
                                                            placeholder="Whatsapp (opcional)" />
                                                        <Input
                                                            name="segment"
                                                            type="text"
                                                            onChange={(e) => setForm({ ...form, segment: e.target.value })}
                                                            style={{ border: 'none', borderRadius: '5px', marginTop: '10px' }}
                                                            placeholder="Segmento (opcional)" />

                                                        <Input
                                                            name="description"
                                                            type="textarea"
                                                            rows="5"
                                                            onChange={(e) => setForm({ ...form, description: e.target.value })}
                                                            style={{ border: 'none', borderRadius: '5px', marginTop: '10px' }}
                                                            placeholder="Descrição do orçamento (opcional)" />

                                                        <InputFile
                                                            file={file}
                                                            setFile={setFile}
                                                        />

                                                    </div>
                                                    :
                                                    <div>
                                                        <FormLabel>
                                                            <h5 className="text-color-primary">Consumo médio mensal em reais:&nbsp;
                                                                <span style={{ color: '#048585' }}>R${form.consumption},00 </span>
                                                            </h5>
                                                        </FormLabel>
                                                        <Slider setForm={setForm} form={form} />
                                                    </div>
                                                }

                                                {loading ?
                                                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                                                        <Loader
                                                            type="TailSpin"
                                                            color="#048585"
                                                            height={50}
                                                        />
                                                    </div>
                                                    :
                                                    <div style={{ display: 'flex', marginTop: '20px' }}>
                                                        {done ?
                                                            <div style={{ display: 'flex', width: '100%', height: '50px' }}>
                                                                <Button onClick={() => handleSend()} color="primary" style={{ display: 'flex', width: '100%', height: '50px' }}>Solicitar orçamento</Button>
                                                            </div>
                                                            :
                                                            <Button onClick={() => handleSubmit()} style={{ width: '100%', height: '50px' }} type="submit" color="primary">Calcular</Button>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </FormHint>
                                </div>
                            }
                        </div>
                        :
                        <div style={{ height: '50vh', marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Loader
                                type="TailSpin"
                                color="#048585"
                                height={50}
                            />
                        </div>
                    }
                </div>
            </div>
        </section >
    );
}


Economy.propTypes = propTypes;
Economy.defaultProps = defaultProps;

export default Economy;