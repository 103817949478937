import CookieConsent from 'react-cookie-consent'
import { trackPage } from '../../../analytics/index'
import React from 'react'

const CookiesPopUp = () => {
    return (
        <div>
            <CookieConsent
                location="bottom"
                style={styles.containerStyle}
                buttonStyle={styles.buttonStyle}
                buttonText="Aceitar"
                cookieName="CookieConsent"
                expires={365}
                onAccept={() => trackPage()}
            >
                <center>
                    Esse site utiliza cookies para otimizar sua experiência.
                    Veja aqui as&nbsp;
                    <a sx={styles.linkStyle} href="/privacy"><u>políticas de privacidade</u></a>.
                </center>
            </CookieConsent>
        </div>
    )
}

const styles = {
    containerStyle: {
        backgroundColor: '#048585',
        minWidth: '250px',
    },
    buttonStyle: {
        backgroundColor: '#004352', color: 'white', borderRadius: '5px'
    },
    linkStyle: {
        color: 'white'
    }
}

export default CookiesPopUp