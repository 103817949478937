import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash'

export default function StyledDropzone({ file, setFile }) {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({ accept: 'image/*, .pdf, .doc, audio/*, video/*', });

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    return (
        <div className="container">
            <div
                onChange={(e) => {
                    const files = []
                    _.forEach(e.target.files, file => {
                        const reader = new FileReader()
                        reader.readAsDataURL(file)
                        reader.onload = () => {
                            files.push({ disposition: 'attachment', filename: file.name, type: file.type, size: file.size, content: reader.result })
                        }
                    })
                    setFile({ ...file, file: files })
                }}
                {...getRootProps({
                    style,
                    accept: 'image/*, .pdf, .doc, audio/*, video/*',
                    multiple: true
                })}
            >
                <input type="file" name="file" id="file" {...getInputProps()} />
                {files.length > 0 ?
                    <aside>
                        <ul>{files}</ul>
                    </aside>
                    :
                    <p style={pStyle}>Arraste aqui uma foto da última fatura de energia da sua instalação.</p>
                }
            </div>
        </div >
    );
}

const pStyle = { paddingTop: '15px' }

const baseStyle = {
    margin: 0,
    marginTop: '15px',
    padding: 0,
    height: '15vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#9CA9B3',
    borderRadius: '10px',
    borderStyle: 'dashed',
    backgroundColor: '#EFF0F0',
    color: '#9CA9B3',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#9CA9B3'
};

const acceptStyle = {
    borderColor: '#9CA9B3'
};

const rejectStyle = {
    borderColor: '#ff1744'
};