import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';
import emailjs from 'emailjs-com'
import api from '../../services/api'
import Loader from "react-loader-spinner";
import Alert from '../elements/Alert'
import _ from 'lodash'

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const [email, setEmail] = useState()
  const [sent, wasSent] = useState(false)
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("")

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    if (_.isEmpty(email)) {
      setErr("Campo obrigatório não preenchido")
      return
    }
    const data = { email, itsAllowed: true, from: "solareasy" }

    await api.sendNewLead(data)
      .then(() => {
        wasSent(true)
        setLoading(false)
      })
      .catch(() => {
        setErr("Ops, algo errado aconteceu. Tente mais tarde!")
        setLoading(false)
      })

  }


  const outerClasses = classNames(
    'cta section center-content-mobile ',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      style={styles.backgroundStyle}
      {...props}
      className={outerClasses}
    >
      <div className="container" >
        <div className={innerClasses} style={styles.containerStyle}>
          <div className="cta-slogan">
            <h3 className="m-0">
              Quer receber novidades sobre o produto?
            </h3>
          </div>
          {!sent ?
            <div className="cta-action">
              {loading ?
                <Loader
                  type="Oval"
                  color="white"
                  height={50}
                />
                :
                err ?
                  <>
                    {err && <Alert text={err} setParam={setErr} type="error" />}
                  </>
                  :
                  <>
                    <Input onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Seu email" ></Input>
                    <Button type="submit" onClick={(e) => handleSubmit(e)} style={styles.buttonStyle}>Enviar</Button>
                  </>
              }
            </div>
            :
            <div>
              <h6 className="m-0">
                Email enviado!
              </h6>
            </div>
          }
        </div>
      </div>
    </section >
  );
}

const styles = {
  backgroundStyle: {
    backgroundColor: 'white'
  },
  containerStyle: {
    borderRadius: '5px'
  },
  buttonStyle: {
    marginTop: '5px',
    width: '100%'
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;