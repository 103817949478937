import React from 'react';
// import sections
import Economy from '../components/sections/Economy'

const Budget = () => {

    return (
        <>
            <Economy />
        </>
    );
}

export default Budget;