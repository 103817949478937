import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 300 + theme.spacing(3) * 2,
    },
    margin: {
        height: theme.spacing(3),
    },
}));

const PrettoSlider = withStyles({
    root: {
        marginTop: '10px',
        color: '#048585',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
        width: '74em',
        minWidth: '20em',
        maxWidth: '76vw'
    },
})(Slider);


export default function CustomizedSlider({ setForm, form })
{
    const classes = useStyles();

    const handleSliderChange = (event, newValue) =>
    {
        setForm({ ...form, consumption: newValue });
    };

    return (
        <div className={classes.root}>
            <PrettoSlider
                min={0}
                max={5000}
                value={typeof form.consumption === 'number' ? form.consumption : 0}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                valueLabelDisplay="on"
                aria-label="slider" />
        </div>
    );
}
