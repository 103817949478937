import React from 'react';
// import sections
import FAQ from '../components/sections/FAQ';

const Faq = () => {

    return (
        <>
            <FAQ />
        </>
    );
}

export default Faq;