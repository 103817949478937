import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Faq from './views/Faq';
import Contact from './views/Contact';
import About from './views/About';
import HowWorks from './views/HowWorks';
import Budget from './views/Budget'
import Privacy from './views/Privacy'

//ga
import { trackPage } from './analytics/index';

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    trackPage()
    document.body.classList.add('is-loaded')
    childRef.current.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/faq" component={Faq} layout={LayoutDefault} />
          <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault} />
          <AppRoute exact path="/about" component={About} layout={LayoutDefault} />
          <AppRoute exact path="/how-works" component={HowWorks} layout={LayoutDefault} />
          <AppRoute exact path="/budget" component={Budget} layout={LayoutDefault} />
          <AppRoute exact path="/privacy" component={Privacy} layout={LayoutDefault} />
        </Switch>
      )} />
  );
}

export default App;