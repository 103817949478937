import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import FormLabel from '../elements/FormLabel'
import Input from '../elements/Input'

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const FAQ = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {

    const [open, setOpen] = useState({})

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section
            {...props}
            className={outerClasses}
            style={styles.background}
        >
            <div className="container reveal-from-bottom" data-reveal-delay="200" style={styles.container}>
                <div className={innerClasses}>
                    <div className="faq-content">
                        <h3 className="mt-0 mb-0 text-color-primary" >
                            Perguntas frequentes
                        </h3>
                        <div style={styles.faq}>
                            <Input onClick={() => setOpen({ 1: !open[1] })} style={styles.inputStyle} value="1. Terei que instalar algum equipamento?"></Input>
                            {open[1] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>
                                        Não é necessário instalar qualquer equipamento, placa ou medidor em seu imóvel. A energia elétrica continuará chegando através do mesmo sistema de rede elétrica de sua cidade/região.
                                    </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 2: !open[2] })} style={styles.inputStyle} value="2. Quem pode aderir?"></Input>
                            {open[2] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Pessoas Jurídicas titulares da conta de energia do local.</FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 3: !open[3] })} style={styles.inputStyle} value="3. A SolarEasy faz toda a intermediação com a minha distribuidora local?"></Input>
                            {open[3] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Sim! Você não precisa se preocupar com nenhum processo burocrático e entrega de solicitações à sua distribuidora de energia regional. A SolarEasy cuida de tudo automaticamente. Todas as informações e documentos que solicitamos na hora do seu cadastro são suficientes para que o nosso sistema faça o resto do trabalho.</FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 4: !open[4] })} style={styles.inputStyle} value="4. Se eu trocar de endereço, posso continuar com a mesma conta?"></Input>
                            {open[4] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Sim! Sua conta na SolarEasy continua a mesma pois ela está associada ao CNPJ. Você precisa apenas nos comunicar da mudança para que possamos solicitar a entrega de energia em seu novo endereço, ou seja, em sua nova Unidade Consumidora. O tempo para essa mudança ser efetuada pode variar de acordo com sua distribuidora.
                                        <p>Obs.: Se a mudança também alterar a distribuidora a qual você está registrada, o preço da cota de área de geração pode mudar.</p>
                                    </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 5: !open[5] })} style={styles.inputStyle} value="5. A SolarEasy ainda não atende minha região, por quê?"></Input>
                            {open[5] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Toda a energia elétrica produzida nas cotas de área contratadas pelos associados da SolarEasy é proveniente de grandes e micro-fazendas fotovoltaicas, instaladas em residências e empresas parceiras ou em nossos sítios de produção. Essa energia é colocada na rede de distribuição comum, que são as empresas gestoras da sua região, quem lhe fornece energia atualmente, por isso, precisamos ter fontes de produção em cada região e fazer a ligação individualmente de acordo com cada legislação local. São processos que o membro da SolarEasy não precisará se envolver, nós cuidamos de toda papelada, mas pode levar um tempinho até conseguirmos chegar na sua região.
                                    </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 6: !open[6] })} style={styles.inputStyle} value="6. O que são cotas de áreas de geração? E onde as áreas estão localizadas?"></Input>
                            {open[6] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Cotas de áreas de geração são "lotes"pré-estabelecidos das nossas fazendas fotovoltaicas, que o associado da SolarEasy têm direito a usufruir dos resultados da produção energética. </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 7: !open[7] })} style={styles.inputStyle} value="7. Quando você contrata uma cota de área de geração, nós destinamos a produção energética de um destes lotes para você."></Input>
                            {open[7] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Como a incidência solar pode variar bastante dependendo da localização de cada cota e das condições climáticas de cada dia, o nosso sistema foi desenhado para manter uma entrega de resultados o mais homogênea possível e constante aos membros. </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 8: !open[8] })} style={styles.inputStyle} value="8. O que acontece quando sobra ou falta kW em minha cota mensal?"></Input>
                            {open[8] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Pode ficar tranquilo! Nós entendemos que o consumo mensal de energia varia todos os meses, então quando você for contratar vamos supôr que você contratou uma área de geração equivalente a 300kWh/mês, mas consumiu apenas 250kWh/mês. Essa sobra de 50kWh/mês ficará armazenada e disponível para debitar da sua próxima fatura ou quando faltar.
                                        Agora vamos supôr que o inverso aconteceu, no mês de fevereiro você consumiu 350 kW, sendo que sua cota contratada gera apenas 300kWh mensal. Neste caso os 50 kWh faltantes serão contabilizados diretamente pela distribuidora. O valor de cobrança desta diferença virá diretamente na fatura da distribuidora de energia da sua região.
                                        Recomendamos que você sempre assine a mais do que consome, já que a energia que sobra não é perdida, continua como crédito para a próxima fatura. Desta forma você tem controle de que consumirá uma energia mais em conta e sustentável.
                                    </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 9: !open[9] })} style={styles.inputStyle} value="9. Em quanto tempo os kWh/mês excedentes expiram?"></Input>
                            {open[9] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Conforme o 2º parágrafo do Artigo 6 da Resolução Normativa nº 687, os créditos gerados pela compensação energética não utilizados ficam disponíveis por até 60 meses (5 anos).
                                        "(...) a energia ativa injetada no sistema de distribuição pela unidade consumidora será cedida a título de empréstimo gratuito para a distribuidora, passando a unidade consumidora a ter um crédito em quantidade de energia ativa a ser consumida por um prazo de 60 (sessenta) meses. "
                                    </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 10: !open[10] })} style={styles.inputStyle} value="10. Como a energia vai chegar na minha casa?"></Input>
                            {open[10] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>A energia gerada nas fazendas fotovoltaicas da SolarEasy é colocada na rede de distribuição já em funcionamento de cada região. Sendo assinante da SolarEasy sua conta da distribuidora virá com os créditos de kWh/mês contratada já descontada.</FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 11: !open[11] })} style={styles.inputStyle} value="11. No momento em que eu entrar na SolarEasy já estarei recebendo energia renovável logo de cara?"></Input>
                            {open[11] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Após a assinatura, se você estiver em uma área de cobertura você deve contratar a quantidade de créditos necessários e a previsão é que sua fatura seguinte já venha com o desconto. Embora o crédito das cotas possa demorar até 90 dias para cair, isso varia de acordo com os processos internos de cada distribuidora de energia.
                                        Caso você não esteja em uma região coberta por nossas usinas, será necessário aguardar até nossa cobertura da região.
                                    </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 12: !open[12] })} style={styles.inputStyle} value="12. Sendo membro da SolarEasy como vem a fatura da distribuidora?"></Input>
                            {open[12] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Sua fatura da distribuidora continuará chegando da mesma maneira, o que você irá observar de diferente é que a quantidade de kWh/mês contratados das áreas da SolarEasy virão abatidos na sua fatura.</FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 13: !open[13] })} style={styles.inputStyle} value="13. Por que continuarei pagando as tarifas da distribuidora?"></Input>
                            {open[13] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Quando você consome energia em sua residência ou ambiente de trabalho, esta energia passou por três tipos diferentes de serviço: o serviço de produção de energia, o serviço de transmissão e o serviço de distribuição.
                                        A SolarEasy se encarrega da função de produzir a energia, de uma maneira verde e renovável, colocando-a na rede de transmissão. Por sua vez o sistema de transmissão, entrega a energia para as distribuidoras que fazem com que a energia elétrica chegue até os seus usuários. As empresas de transmissão e distribuição ainda são necessárias para fazer conduzir a energia, logo, a tarifa que você continuará pagando na sua fatura da distribuidora serve para cobrir os custos operacionais e impostos destes prestadores de serviço. Estas tarifas são calculadas pelo órgão regulador, a ANEEL.
                                        A Assinatura da SolarEasy garante um desconto de até 15% da fatura da sua conta de energia.
                                        <p>Entenda as tarifas que você paga para a distribuidora:
                                            <a style={{ color: "#048585" }} href="https://www.aneel.gov.br/entendendo-a-tarifa/-/asset_publisher/uQ5pCGhnyj0y/content/composicao-da-tarifa/654800?inheritRedirect=false&redirect=http://www.aneel.gov.br/entendendo-a-tarifa%3Fp_p_id%3D101_INSTANCE_uQ5pCGhnyj0y%26p_p_lifecycle%3D0%26p_p_state%3Dnormal%26p_p_mode%3Dview%26p_p_col_id%3Dcolumn-2%26p_p_col_pos%3D1%26p_p_col_count%3D2">
                                                &nbsp;Como é composta a tarifa - Entendendo a Tarifa
                                            </a>
                                        </p>
                                    </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 14: !open[14] })} style={styles.inputStyle} value="14. Se em algum mês eu não efetuar o pagamento dos créditos de energia na SolarEasy , ficarei sem luz?"></Input>
                            {open[14] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Não.
                                        Você não ficará sem luz pois a energia continuará sendo fornecida normalmente, o que altera neste caso é a forma de pagamento, a cobrança será feita diretamente pela distribuidora, através da sua fatura mensal. A energia utilizada neste mês também não será proveniente de nossas fontes renováveis, neste caso você estará utilizando energia comum entregue pela distribuidora. O fornecimento de energia será cortado apenas se você não pagar pelos créditos de energia da SolarEasy e também não pagar a fatura da distribuidora.
                                    </FormLabel>
                                </div>
                            }
                            <Input onClick={() => setOpen({ 15: !open[15] })} style={styles.inputStyle} value="15. Como é feita a cobrança?"></Input>
                            {open[15] &&
                                <div style={styles.divLabel}>
                                    <FormLabel style={styles.label}>Não.
                                        No momento a cobrança é feita mensalmente através de boleto bancário.
                                    </FormLabel>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

FAQ.propTypes = propTypes;
FAQ.defaultProps = defaultProps;

const styles = {
    background: { backgroundColor: 'white', },
    container: { marginTop: '50px' },
    faq: { marginTop: '20px', marginBottom: '-80px' },
    inputStyle: { fontSize: '20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '10px' },
    divLabel: { padding: '15px' },
    label: { color: "primary", fontSize: '18px', }
}

export default FAQ;