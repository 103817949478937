import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '10px',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
}));

export default function TransitionAlerts({ text, type, setParam }) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setOpen(false)
            setParam && setParam(false)
        }, 5000)
    }, []);

    return (
        <div className={classes.root}>
            <Collapse in={open}>
                <Alert
                    severity={type}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {text}
                </Alert>
            </Collapse>
        </div>
    );
}
