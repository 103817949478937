import React from 'react';
import classNames from 'classnames';
import linkedin from '../../../assets/images/linkedin.png'
import instagram from '../../../assets/images/instagram.png'
import whatsapp from '../../../assets/images/whatsapp.png'

//ga 
import { logEvent } from '../../../analytics/index';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a style={styles.aStyle}
            onClick={() => {
              logEvent('https://www.wa.link/b8xcik/', 'Redirect')
              window.open("https://www.wa.link/b8xcik/")
            }}>
            <img src={whatsapp} width="30"></img>
          </a>
        </li>
        <li>
          <a style={styles.aStyle}
            onClick={() => {
              logEvent('https://www.linkedin.com/company/grupo-fioforte', 'Redirect')
              window.open("https://www.linkedin.com/company/grupo-fioforte")
            }}>
            <img src={linkedin} width="30"></img>
          </a>
        </li>
        <li>
          <a style={styles.aStyle}
            onClick={() => {
              logEvent('https://www.instagram.com/grupofioforte/', 'Redirect')
              window.open("https://www.instagram.com/grupofioforte/")
            }}>
            <img src={instagram} width="30"></img>
          </a>
        </li>
      </ul>
    </div >
  );
}

const styles = {
  aStyle: {
    cursor: 'pointer'
  }
}

export default FooterSocial;