import React, { useEffect } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const AboutUs = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) =>
{

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap start-content',
        pushLeft && 'push-left'
    );

    return (
        <section
            {...props}
            style={styles.backgroundStyle}
            className={outerClasses}
        >
            <div className="container reveal-from-bottom" data-reveal-delay="200" style={styles.containerStyle}>
                <div className={innerClasses}>
                    <h4 className='text-color-primary'>SOMOS A SOLAREASY, UMA EMPRESA QUE NASCEU PARA MUDAR A RELAÇÃO DO BRASILEIRO COM O SETOR ENERGÉTICO</h4>
                    <p>
                        A SolarEasy é uma empresa do Grupo FioForte, criada com o propósito de oferecer soluções inovadoras, inteligentes e ecológicas para o uso eficiente dos recursos energéticos.
                        A SolarEasy atua no mercado de geração distribuída, universalizando e incentivando o uso da energia solar com valores mais atrativos que os praticados pelo mercado de energia convencional.
                    </p>
                    <p>
                        Ou seja, comércios, podem aderir ao uso da energia solar e economizar. Os interessados podem aderir ao modelo de forma totalmente digital. A energia é gerada remotamente, em áreas com radiação solar mais favorável e chega pela rede da distribuidora, porém, com menor custo.
                    </p>
                    <p>O valor do desconto médio oferecido aos clientes é de até 15% e não é necessário nenhum tipo de investimento ou obra. É energia solar por assinatura na sua empresa!</p>
                </div>
            </div>
        </section>
    );
}

const styles = {
    backgroundStyle: {
        backgroundColor: 'white'
    },
    containerStyle: {
        marginTop: '50px',
        minHeight: '670px'
    }
}

AboutUs.propTypes = propTypes;
AboutUs.defaultProps = defaultProps;

export default AboutUs;