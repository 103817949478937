import ReactGa from 'react-ga'
import isCookieConsent from '../utils/general'

export const trackPage = () => {
    if (isCookieConsent()) {
        initGA();
        logPageView();
    }
}

const initGA = () => {
    if (!window.GA_INITIALIZED) {
        console.log('GA init');
        ReactGa.initialize("UA-203922062-2");
        window.GA_INITIALIZED = true;
    }
};

const logPageView = () => {
    console.log(`Logging pageview for ${window.location.pathname}`);
    ReactGa.set({ page: window.location.pathname });
    ReactGa.pageview(window.location.pathname + window.location.search);
};

export const logEvent = (category = '', action = '') => {
    if (category && action) {
        ReactGa.event({ category, action });
    }
};

export const logException = (description = '', fatal = false) => {
    if (description) {
        ReactGa.exception({ description, fatal });
    }
};
