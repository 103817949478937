import { create } from 'axios';

const base = 'https://api-grupofioforte.herokuapp.com/'
// const base = 'http://localhost:8000/'

const api = create({
    baseURL: base,
});

const sendEmail = (content) => {
    return api.post('/email', content)
}

const sendNewLead = (data) => {
    return api.post('/new-lead', data)
}

export default {
    sendEmail,
    sendNewLead
}