import React from 'react';

// import sections
import FeaturesWorks from '../components/sections/FeaturesWorks';

const HowWorks = () => {

    return (
        <FeaturesWorks invertMobile imageFill className="illustration-section-02" />
    );
}

export default HowWorks;