import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import FormHint from '../elements/FormHint'
import FormLabel from '../elements/FormLabel'
import Input from '../elements/Input'
import Button from '../elements/Button'
import Radio from '../elements/Radio'
import _ from 'lodash'
import api from '../../services/api'
const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const ContactUs = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) =>
{
    const [typePerson, setTypePerson] = useState('física')
    const [sent, wasSent] = useState(false)
    const [result, setResult] = useState(false)
    const [form, setForm] = useState({
        name: '',
        cpf: 'Não informado',
        cnpj: 'Não informado',
        enterprise: 'Não informado',
        phone: 'Não informado',
        email: '',
        description: '',
    })

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );


    const handleSend = async () =>
    {
        if (_.isEmpty(form.name) || _.isEmpty(form.email) || _.isEmpty(form.description)) return

        form.title = `Novo contato da Solareasy - ${form.name}`
        // form.to = process.env.REACT_APP_EMAIL_SENDGRID_SHIPPING
        form.to = process.env.EMAIL_SENGRID_DESTINATION
        form.from = process.env.REACT_APP_EMAIL_SENDGRID_SHIPPING
        form.templateId = process.env.REACT_APP_TEMPLATE
        await api.sendEmail(form)
            .then(() => 
            {
                setResult({
                    title: 'Obrigado pelo contato.',
                    description: 'Em breve nosso time entrará em contato por email ou telefone!'
                })
                wasSent(true)
                setTimeout(function ()
                {
                    wasSent(false)
                }, 3000)
            })
            .catch((err) =>
            {
                setResult({ title: 'Ops, algo errado!', description: 'Tente novamente' })
                wasSent(true)
                setTimeout(function ()
                {
                    wasSent(false)
                }, 3000)
            })

    }


    return (
        <section
            {...props}
            style={styles.backgroundStyle}
            className={outerClasses}
        >
            <div className="container reveal-from-bottom" data-reveal-delay="200" style={styles.containerStyle}>
                <div className={innerClasses}>
                    <div className="contact-content">
                        {!sent ?
                            <div>
                                <h3 className="mt-0 mb-0 text-color-primary" >
                                    Contato
                                </h3>
                                <FormHint onSubmit={handleSend}>
                                    <Input type="text" name="name" onChange={(e) => setForm({ ...form, name: e.target.value })}
                                        style={styles.inputStyle} placeholder="Nome completo" />
                                    <div style={{ marginTop: '10px' }}>
                                        <FormLabel style={styles.formStyle}>
                                            <Radio
                                                name="typePerson"
                                                checked={typePerson === 'física' ? true : null}
                                                onClick={() => setTypePerson('física')}
                                            />
                                            Pessoa física
                                        </FormLabel>
                                        <FormLabel style={styles.formStyle}>
                                            <Radio
                                                name="typePerson"
                                                checked={typePerson === 'jurídica' ? true : null}
                                                onClick={() => setTypePerson('jurídica')}
                                            />
                                            Pessoa jurídica
                                        </FormLabel>
                                    </div>
                                    {typePerson === 'física' &&
                                        <Input onChange={(e) => setForm({ ...form, cpj: e.target.value })} name="cpf" type="number" size="99999999999"
                                            style={styles.inputStyle} placeholder="CPF" />}
                                    {typePerson === 'jurídica' &&
                                        <div>
                                            <Input onChange={(e) => setForm({ ...form, cnpj: e.target.value })} name="cnpj" type="number" min="10000000000000" max="99999999999999" style={styles.inputStyle} placeholder="CNPJ" />
                                            <Input onChange={(e) => setForm({ ...form, enterprise: e.target.value })} style={styles.inputStyle} placeholder="Nome da empresa" />
                                        </div>
                                    }
                                    <Input onChange={(e) => setForm({ ...form, phone: e.target.value })} name="phone" type="phone" style={styles.inputStyle} placeholder="Telefone para contato" />
                                    <Input onChange={(e) => setForm({ ...form, email: e.target.value })} name="email" type="email" style={styles.inputStyle} placeholder="Email para contato" />
                                    <Input onChange={(e) => setForm({ ...form, description: e.target.value })} name="description" type="textarea" rows="4" style={styles.inputStyle} placeholder="Mensagem" />

                                    <Button onClick={() => handleSend()} style={styles.buttonStyle} color="primary">Enviar</Button>
                                </FormHint>
                            </div>
                            :
                            <div style={styles.resultStyle}>
                                <h3 className="mt-0 mb-16 text-color-primary">{result.title}</h3>
                                <p>{result.description}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

const styles = {
    backgroundStyle: {
        backgroundColor: 'white',
    },
    containerStyle: {
        marginTop: '50px',
        minHeight: '670px'
    },
    inputStyle: {
        border: 'none',
        borderRadius: '5px',
        marginTop: '10px'
    },
    buttonStyle: {
        borderRadius: '5px',
        marginTop: '10px'
    },
    resultStyle: {
        marginTop: '100px', height: '40vh', justifyContent: 'center'
    },
    formStyle: {
        marginLeft: '10px'
    }
}

ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;

export default ContactUs;