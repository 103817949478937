import React from 'react';
// import sections
import ContactUs from '../components/sections/ContactUs'

const Contact = () => {

    return (
        <>
            <ContactUs />
        </>
    );
}

export default Contact;