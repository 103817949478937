import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
}) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
    );

    const sectionHeader = {
        title: 'Como funciona energia solar por assinatura?',
        // paragraph: 'Cras ultricies ligula sed magna dictum porta. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.'
    };

    return (
        <section
            {...props}
            style={{ backgroundColor: 'white' }}
            className={outerClasses}
        >
            <div className="container reveal-from-bottom" data-reveal-delay="200">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={splitClasses}>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">

                                <h3 className="mt-0 mb-12 text-color-primary" >
                                    # 01
                                </h3>
                                <p className="m-0">
                                    As nossas fazendas solares são instaladas em lugares onde o sol é forte e abundante.
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    loading="lazy"
                                    src={require('./../../assets/images/painel-solar.png')}
                                    style={{
                                        width: '300px',
                                    }}
                                />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">

                                <h3 className="mt-0 mb-12 text-color-primary">
                                    # 02
                                </h3>
                                <p className="m-0">
                                    Em cada fazenda, centenas de placas fotovoltaicas (placas solares) captam a luz do sol e a transformam em energia elétrica.
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    loading="lazy"
                                    src={require('./../../assets/images/energia-solar.png')}
                                    style={{
                                        width: '300px',
                                    }} />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">

                                <h3 className="mt-0 mb-12 text-color-primary">
                                    # 03
                                </h3>
                                <p className="m-0">
                                    Você contrata um lote das nossas fazendas e a energia chega até a sua empresa, residência ou condomínio, onde quer que ela esteja, pelas distribuidoras de energia.
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    loading="lazy"
                                    src={require('./../../assets/images/torre-de-eletricidade.png')}
                                    style={{
                                        width: '300px',
                                    }} />
                            </div>
                        </div>

                        <div className="split-item">
                            <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">

                                <h3 className="mt-0 mb-12 text-color-primary">
                                    # 04
                                </h3>
                                <p className="m-0">
                                    Pronto! Seu desconto vem abatido na sua conta de energia. Se a energia contratada não for utilizada fica acumulada para o próximo mês.
                                </p>
                            </div>
                            <div className={
                                classNames(
                                    'split-item-image center-content-mobile reveal-from-bottom',
                                    imageFill && 'split-item-image-fill'
                                )}
                                data-reveal-container=".split-item">
                                <Image
                                    loading="lazy"
                                    src={require('./../../assets/images/bateria.png')}
                                    style={{
                                        width: '300px',
                                    }} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;