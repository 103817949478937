import React from 'react';

// import sections
import PrivacyCookies from '../components/sections/PrivacyCookies'

const Privacy = () => {

    return (
        <>
            <PrivacyCookies />
        </>
    );
}

export default Privacy;